/* eslint-disable */
export default new function folder($) {
  const self = this;

  this.init = () => {
    // On load…
    $(function(){
      
      const $folder = $('.js--folder');

      $folder.on('click', onFolderClick);

      function onFolderClick(e){
        e.preventDefault();
        $(this).toggleClass('active');
        const icon = $(this).hasClass('active') ? '-' : '+';
        $(this).prev().text(icon);
        const $folderItem = $(this).closest('.js--folder__root');
        $folderItem.toggleClass('is-open');
        $folderItem.find('.js--folder__content').slideToggle(200);
      }
    });
  };

  this.selectorCache = () => {};

  this.addEvents = () => {};

  this.eventHandlers = {};

  this.helpers = {};

  return this.init;
}(jQuery);
