/* eslint-disable */
export default new function routeCases($) {
  const self = this;

  this.init = () => {
		$(document).on('ready', function(){
			if(!bodyClassRouter()) return false; 

			});
		
		function setBrowserToTop(){
			$('html,body').animate({scrollTop: 0}, 200);
		}

		// Routed scripts
		function bodyClassRouter(){
			return $('body').hasClass('page-template-template-case'); 
		}
	}
	return this.init; 

}(jQuery);