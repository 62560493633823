/* eslint-disable */
export default new function folder($) {
  const self = this;

  this.init = () => {
   this.addEvents();

   function isBigWin(){
        return $(window).width() > 768; 
    }
    /**
     * Calculate nav canvas
     */
    function calculateCanvasRadius() {
        const $canvas = $('.nav__canvas');
        const $window = $(window);
        const win_w = $window.width();
        const win_h = $window.height();

        const winCornerDistance = Math.sqrt((win_w * win_w) + (win_h * win_h));
        return winCornerDistance;
    }

    function calculateNewProps() {

        const winCornerDistance = calculateCanvasRadius();
        const dynamicVal = {};
        dynamicVal.left = isBigWin() ? 30 : 0;
        dynamicVal.right = !isBigWin() ? -5 : 'auto';

        return {
            width: winCornerDistance * 2,
            height: winCornerDistance * 2,
            borderRadius: winCornerDistance,
            left: dynamicVal.left,
            right: dynamicVal.right
        }
    }

    function whenMenuIsHidden() {
        return new Promise((resolve) => {
            $('.overlay-menu').animateAndThen('fadeOutUpSmall').then(() => {
                $('.overlay-menu').removeClass('active');
                resolve();
            });
        });
    }

    function getInitProps() {

        const dynamicVal = {};
        dynamicVal.left = isBigWin()    ? 60 : $(window).width() - 35;
        dynamicVal.right = !isBigWin()  ? -5 : 'auto';

        return {
            width: $('.nav__canvas').css('width'),
            height: $('.nav__canvas').css('height'),
            borderRadius: $('.nav__canvas').css('border-radius'),
            left: dynamicVal.left,
            right: $('.nav__canvas').css('right')
        }
    }

    function whenScrolledTo(target){
        return new Promise((resolve) => {
            $('html, body').animate({ 
                scrollTop: $(target).offset().top 
            }, 200, resolve);
        });
    }
    // On load…
    $(function () {

        let initProps = getInitProps();
        const aniDuration = 200;
        if(!isBigWin()){
            $('.nav__canvas').css('left', $(window).width() - 35 ); // Switch On
        }
        // Handling the canvas if resized
        $(window).on('resize', () => {
            $('.nav__canvas').attr('style', '');
            initProps = getInitProps();
        });

        // Handling the canvas if resized
        $(window).on('resize', () => {
            $('.nav__canvas').attr('style', '');
            initProps = getInitProps();
        });

        // $('.js--open-menu').on('click', )
        $('.js--open-menu').click(function (e) {
            e.preventDefault();
            if (!$('.nav__canvas').hasClass('active')) {
                $(this).addClass('active'); // Switch On
                $('.nav__canvas').addClass('active'); // Switch On
                $('.nav__canvas').animate(calculateNewProps(), aniDuration, 'easeOutCubic', () => {
                    $('.overlay-menu').css('animation-duration', '200ms').animateCss('fadeInUpSmall').addClass('active');
                });
                

            } else {
                $('.nav__canvas').removeClass('active'); // Switch Off
                whenMenuIsHidden().then(() => {
                    $('.nav__canvas').animate(initProps, aniDuration, 'easeOutCubic');
                    $(this).removeClass('active'); // Switch On
                });
            }

        });

        // jump to # on page
        $('#menu-main-menu p').find('a').on('click', function (e) {
            const url = $(this).attr('href');
            const hash = url.match(/#.+$/)[0].toString(); 
            
            if( /#/.test(url) 
                &&
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname){
                e.preventDefault();

                whenScrolledTo(hash).then(() => {
                    $('.nav__canvas').removeClass('active'); // Switch Off
                    whenMenuIsHidden().then(() => {
                        $('.nav__canvas').animate(initProps, aniDuration, 'easeOutCubic');
                        $('.js--open-menu').removeClass('active'); // Switch On
                    });     
                });
            }
            
        })

    });
  };

  this.selectorCache = () => {};

  this.addEvents = () => {
    // Fix for weird closing menu behavior
    $('.nav_canvas, .overlay-menu').on('touchmove', (e) => {
        e.preventDefault();
    });
  };

  this.eventHandlers = {};

  this.helpers = {};

  return this.init;
}(jQuery);
