/* eslint-disable */
export default new function routeStart($) {
  const self = this;

  this.init = () => {

    function onLoad(){
      $('#slide_logo a').on('click', (e)=>{
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $('#first').offset().top
        }, 600);
      });
    }

    $(onLoad);
  }

  return this.init; 

}(jQuery);