/* eslint-disable */
export default new function caseBox($) {
  const self = this;

  this.init = () => {
     // On load…
    $(function(){

      function isSm(){
        return ($(window).width() < 780);
      }

      function gotoUrl(url){
        window.location.href = url;
      }

      function isTouch(){
        return 'ontouchstart' in window || navigator.msMaxTouchPoints;
      }

      /* ------------------
      *     Case Box
      * ------------------ */

      var $actionBtn = $('.js--transition-to-cover');

      $actionBtn.on('click', (e) =>{

        if(isTouch()) return true;

        e.preventDefault();
        const $clickedItem  = $(e.delegateTarget);

        const $frame        = $clickedItem.closest('.full-framer');
        const redirect      = gotoUrl.bind(null, $clickedItem.attr('href'));

        $('html, body').animate({ scrollTop: $frame.offset().top }, 200);

        whenNavIsHidden().then(redirect);
      });

      function hideNav(){
        $('.nav').animate({left: -120}, 100);
        $('.full-framer__ctas').animate({height: 0}, 200);
      }

      function whenNavIsHidden() {
        return new Promise((resolve) => {
          hideNav();
          resolve();
        });
      }

      $('.js--transition-to-cases').on('click', onGotoCasesClick);

      function onGotoCasesClick(e){

        if(isTouch()) return true;

        e.preventDefault();
        const $clickedItem = $(e.target);
        const redirect = gotoUrl.bind(null, $clickedItem.closest('a').attr('href') + '#wrap');
        $('html, body').animate({ scrollTop: $clickedItem.closest('.full-framer').offset().top }, 200);

        $clickedItem.closest('.full-framer').find('.full-framer__title').fadeOut(200);
        $clickedItem.closest('.full-framer').find('.full-framer__post-content').fadeOut(200);

        whenNavIsHidden().then(whenCaseImagesAreAnimated).then(redirect);
      }

      function whenCaseImagesAreAnimated(){
        return new Promise((resolve) => {
          if(isSm()){
            resolve();
          }else{
            resolve();
            $('.full-framer__img-slide').animate({height: '80vh'}, 300, resolve);
          }

        });
      }

      // Create thow fancy curtains...
      function addTransitionCurtains(){
        const $curtainAbstract  = $('<div></div>').addClass('transition-curtain');
        const $curtainBase      = $curtainAbstract.clone().addClass('bg--base').appendTo('body')
        const $curtainAccent    = $curtainAbstract.clone().addClass('bg--accent').appendTo('body')
      }

      // …Implement them.
      $('.js--transition-swish').on('click', (e)=>{
        if(isTouch()) return true;
        e.preventDefault();
        addTransitionCurtains();
        const redirect = gotoUrl.bind(null, $(e.delegateTarget).attr('href'));
        setTimeout(redirect, 1000);
      });



      /* ------------------
      *     Case View
      * ------------------ */

      var $transitionLink = $('.js--transition-to-case');

      $transitionLink.on('click', (e)=> {

        if(isTouch()) return true;

        e.preventDefault();

        const $link = $(e.delegateTarget);

        $('html, body').animate({ scrollTop: $link.offset().top }, 200)

        function animateText(){
          $link.find('.promoted-item__title').addClass('fadeOut');
          $link.find('.promoted-item__description').addClass('fadeOut animated').css('animation-duration', '200ms');
        }

        setTimeout(animateText, 200);
        function doStuff(){
          $link.addClass('fullscreen');
          gotoUrl($link.attr('href'));
        }
        setTimeout(doStuff, 500);
      });


    });
  };

  this.selectorCache = () => {};

  this.addEvents = () => {};

  this.eventHandlers = {};

  this.helpers = {

  };

  return this.init;
}(jQuery);


