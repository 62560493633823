/* eslint-disable */
export default new function cover($) {
  
  const self = this;
  
  const selectorsChecksOut = function( selectors ){
    const result = Object.keys(selectors).map((v)=>{
      const selector = selectors[v];
      return $(selector).length;
    });
    return $.inArray(0, result) === -1;
  };

  const selector = {
    videoContainer: '.cover--video',
    video: '.cover__video',
    filter: '.cover__filter',
    poster: '.cover__video-poster',
    posterImg: '.cover__video-poster img'
  };

  this.init = () => {
    self.addEvents();
    if(!selectorsChecksOut(selector)) return;
    self.setVars();
  };

  this.setVars = () => {
    const videoPoster = $(selector.posterImg)[0];
    this.posterDimensions = self.helpers.getNaturalProportions(videoPoster);
  };

  this.addEvents = () => {
    $('.js--trigger').on('click', self.eventHandlers.onTriggerBtnClick);
    if(!selectorsChecksOut(selector)) return;
    $(document).on('ready', self.eventHandlers.onLoad);
    $(window).on('resize', self.eventHandlers.onResize);
  };

  this.eventHandlers = {

    onLoad(){
      self.helpers.updateVideoVars();
      self.helpers.scaleVideo();
    },

    onResize(){
      self.helpers.updateVideoVars();
      self.helpers.scaleVideo();
    },
    
    onTriggerBtnClick(e){
      e.preventDefault(); 
      
      $('html, body').animate({ scrollTop: $('#main_content').offset().top + 10 }, 400);
    }

  };

  this.helpers = {
    
    scaleVideo(){
      if(this.getOrientation() === 'portrait'){
        this.setVideoToPortrait();
      }else{
        this.setVideoToLandscape();
      }
    },

    resetVideoStyles(){
      $(selector.video).attr('style', '');
    },
    
    updateVideoVars(){
      self.$videoContainer        = $(selector.videoContainer); 
      self.videoCalculatedWidth   = self.$videoContainer.height()/self.posterDimensions.ratio;
      self.videoCalculatedHeight  = self.$videoContainer.width()*self.posterDimensions.ratio;
    },

    setVideoToLandscape(){
      this.resetVideoStyles();
      if(self.$videoContainer.outerWidth() < self.videoCalculatedWidth){
        this.offsetVideoByX();
      }else{
        this.offsetVideoByY();
      }

    },

    setVideoToPortrait(){
      this.resetVideoStyles();
      this.offsetVideoByX();      
    },

    offsetVideoByY(){
      const offset = (self.$videoContainer.height() - self.videoCalculatedHeight) /2;
      const props = { width: '100%', height: 'auto', top: offset}; 
      $(selector.video).css(props);
    },

    offsetVideoByX(){
      const offset = (self.$videoContainer.width() - self.videoCalculatedWidth) /2;
      const props = {height: '100%', width: 'auto', left: offset}; 
      $(selector.video).css(props);
    },

    getNaturalProportions(element){
      return {  height: element.naturalHeight, 
                width: element.naturalWidth, 
                ratio: element.naturalHeight/element.naturalWidth
              };
    },
    
    getOrientation(){
      return $(window).width() > $(window).height() ? 'landscape' : 'portrait';
    }
  };

  return this.init;
}(jQuery);
