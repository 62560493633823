/* eslint-disable */
export default new function contactFrame($) {
  const self = this;

  this.init = () => {
     $(() => {
      $(document).on('click', '.contacts__nav a', onContactNavClick);
      prepareSlides();
    });

    $(window).on('resize', () => {
      // prepareSlides();
    });

    // Put the first slide on top of the stack n
    // hide the rest
    function prepareSlides(){
      if($(window).width() > 780){
        
        $('.contact').eq(0).addClass('active');
        $('.contact').slice(1).hide();
        $('.contacts__nav li:first a').addClass('active');  
      }else{
        $('.contact').removeClass('active');
        $('.contact').show();
      }
      
    }

    function setActiveItem($item){
      $('.contacts__nav a.active').removeClass('active');
      $item.addClass('active');
    }

    // Return slide
    function getSlide(id){
      return $('.contact').eq(id);
    } 

    function onContactNavClick(e){
      e.preventDefault();
      const newSlideId = $(this).data('target-id');
      const oldSlideId = getActiveSlide();

      whenOutromated(oldSlideId).then( () => {
        const $newSlide = getSlide(newSlideId);
        animateSlideIn($newSlide);
      });
      setActiveItem($(this));
    }

    function getActiveSlide(){
      return $('.contact:visible');
    }

    function animateSlideOut($slide, cb){
      // $slide.find('.contact__body').animateCss('fadeOutLeftSmall');
      // $slide.find('.contact__img').animateCss('fadeOut');
      // setTimeout(()=>{$slide.hide().css('z-index', 0);}, 500);
      $slide.hide().css('z-index', 0);
    }

    function whenOutromated($slide){
      const preparedAnimation = animateSlideOut.bind(null, $slide);
      return new Promise((resolve) => {
        preparedAnimation();
        resolve();
      });
    }
    
    function animateSlideIn($slide){
      $slide.css('z-index', 1).show();
      $slide.find('.contact__body').animateCss('fadeInLeftSmall');
      $slide.find('.contact__img').hide().fadeIn(400);
    }
  };

  this.selectorCache = () => {};

  this.addEvents = () => {};

  this.eventHandlers = {};

  this.helpers = {};

  return this.init;
}(jQuery);
