/* eslint-disable */
export default new function routeLabs($) {
  const self = this;

  this.init = () => {
		$(document).on('ready', function(){
			if(!bodyClassRouter()) return false; 
			setTimeout(setBrowserToTop, 40);   	
			$('.full-framer__content').animateCss('fadeInUpSmall');
			 $('.full-framer__title, .full-framer__description').animateCss('fadeInLeftSmall');
		});
		
		function setBrowserToTop(){
			$('html').animate({scrollTop:0}, 1);
	    $('body').animate({scrollTop:0}, 1);
		}

		// Routed scripts
		function bodyClassRouter(){
			return $('body').hasClass('page-template-template-labs'); 
		}
	}
	return this.init; 

}(jQuery);