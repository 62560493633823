/* eslint-disable */
export default new function blurbHalf($) {
  const self = this;
  // Define inits
  this.init = () => {
    this.addEvents();
  }

  this.addEvents = () => {
    $(this.eventHandlers.onLoad);
    $(window).on('resize', this.eventHandlers.onResize);
  }
  this.eventHandlers = {
    
    onLoad() {
      self.helpers.adjustMargin();
    },

    onResize() {
      if ($(window).width() > 770) {
        self.helpers.adjustMargin();
      } else {
        self.helpers.adjustMargin('reset');
      }
    }

  }
  this.helpers = {
    
    adjustMargin(ctrl) {
      const marginLeft = (ctrl === 'reset') ? 0 : self.helpers.getLeftMargin();
      $('.js--dynamic-grid-item').css({ 'margin-left': marginLeft });
    },

    getLeftMargin() {
      const windowWidth = $(window).width();
      const containerWidth = $('.container').eq(0).width();
      return (windowWidth - containerWidth) / 2;
    }
  }
  return this.init
}(jQuery);
