/* eslint-disable */
export default new function _utils($) {
  const self = this;
  window._utils = self; 

  this.init = () => {
  	this.events();
    this.attachHelpers();
  }

  this.events = () => {
    $(this.eventHandlers.onload);  
  }

  this.eventHandlers =  {
    onload: () => {
      self.helpers.extendJQuery();
    }
  };

  this.attachHelpers = ()=> {
    
   this.bodyClassRouter = ()=> {
      return $('body').hasClass('page-template-template-case'); 
    }

  }

  this.helpers = {

    extendJQuery(){

      var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

      // Extend the libs with the animateCss method
      $.fn.extend({
        animateCss: function (animationName) {       
          this.addClass('animated ' + animationName).on(animationEnd, function() {
            $(this).removeClass('animated ' + animationName);
          });
          return $(this);
        },

        // Provide a promise method
        animateAndThen: function (animationName) {
          const self = this;
          return new Promise( (resolve)=>{
            self.addClass('animated ' + animationName).on(animationEnd, function() {
              $(self).removeClass('animated ' + animationName);
              resolve($(self)); // Passing the object back
            });
          });
        }

      });
    }
  }

  return this.init;
}(jQuery);
