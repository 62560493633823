/* eslint-disable */
export default new function routeCase($) {
  const self = this;

  this.init = () => {
    
    $(document).on('ready', function(){
			if(!bodyClassRouter()) return false; 
			setTimeout(setBrowserToTop, 40);   	
		});
		
		function setBrowserToTop(){
			$('html').animate({scrollTop:0}, 1);
	    $('body').animate({scrollTop:0}, 1);
		}

		// Routed scripts
		function bodyClassRouter(){
			return $('body').hasClass('single-case'); 
		}
  };

  this.selectorCache = () => {};

  this.addEvents = () => {};

  this.eventHandlers = {};

  this.helpers = {};

  return this.init;
}(jQuery);
