/* eslint-disable */
export default new function caseBox($) {

  const self = this;
  let selector = {
    animatedSlide : $('.js--animated-slide')
  }
  self.dr = {}; // Dom registrar

  this.init = () => {
    this.selectorCache();
    this.addEvents();

    // On load…
    $(function(){

        $('#slide_logo').waypoint({
          handler: function(direction) {
            if (direction === 'up') {
              self.dr.nav.hide();
            } else {
              self.dr.nav.fadeIn(500).addClass('nav--dark'); }
          },
          offset: '-100%',
        });


        // ---- Promoted items section ----
        function animateIn($element){
          if($element.children().eq(0).is(':visible')) return;
          $element.children().eq(0).show().animateCss('fadeInLeftSmall');
          $element.children().eq(1).show().animateCss('fadeInUpSmall');
          $element.children().eq(2).show().animateCss('fadeInLeftSmall');
        }
        function animateOut($element){
          alert('Peee!');
          $element.children().eq(0).show().animateAndThen('fadeOutLeftSmall').then(()=>{$element.children().eq(0).hide();});
          $element.children().eq(1).show().animateAndThen('fadeOutUpSmall').then(()=>{$element.children().eq(1).hide();});
          $element.children().eq(2).show().animateAndThen('fadeOutLeftSmall').then(()=>{$element.children().eq(2).hide();});
        }

        $('.js--animated-slide').waypoint({
          handler: function(direction) {
            if(direction==='down'){
              animateIn($(this.element));
            }else{
              // animateOut($(this.element));
            }
          },
          offset: '10%',
        });

        $('.js--animated-slide').waypoint({
          handler: function(direction) {
            if(direction==='down'){
              // animateOut($(this.element));
            }else{
              // animateIn($(this.element));
            }
          },
          offset: '-45%',
        });

        $('.js--case-frame').waypoint({
          handler: function(direction) {
            $(this.element).find('.full-framer__title').animateCss('fadeInUpSmall');
            $(this.element).find('.full-framer__post-content').animateCss('fadeInLeftSmall');
          },
          offset: '50%',
        });

        $('.js--animate-this').hide();
        $('.js--animate-this').waypoint({
          handler: function(direction) {
            $(this.element).show().animateCss('zoomIn')
          },
          offset: '100%',
        });

        $('.mapper').waypoint({
          handler: function(direction) {
            if(direction === 'down'){
              $('.contact-ball').addClass('boom');
            }else{
              $('.contact-ball').removeClass('boom');
            }
          },
          offset: '5%',
        });

      });
  };

  this.selectorCache = () => {
    self.dr.nav             = $('.nav');
  };

  this.addEvents = () => {
    $(self.eventHandlers.onLoaded)
  };

  this.eventHandlers = {
    onLoaded: () => {
      self.helpers.setNavTitle();
      self.helpers.hideNav();
      self.helpers.hideSlideElements();
    }
  };

  this.helpers = {

    hideNav(){
      $('body.home .nav').hide();
    },

    setNavTitle(){
      $('.current-page').text( document.getElementsByTagName("title")[0].innerHTML);
    },

    hideSlideElements(){
      $(selector.animatedSlide).children().slice(0,3).hide();
    }
  };

  return this.init;
}(jQuery);
