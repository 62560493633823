/* eslint-disable */
export default new function tabCanvas($) {
  const self = this;

  this.init = () => {
    // On load…
    $(function(){
      events(); 
    });

    const selectors = {
      tabs : $('.js--tab'),
      tabSentenceItem: $('.js--tab--sentence'),
      sentenceBtn : $('.js--show-sentence-options'),
      sentenceOptions : $('.js--sentence-options'),
      optionsList: $('.js--sentence-options'),
    }; 

    const events = ()=>{
      $(evenHandlers.onDocumentReady);;
      selectors.tabs.on('click', evenHandlers.onTabClick);
      selectors.tabSentenceItem.on('click', evenHandlers.onTabSentenceItem);
      selectors.sentenceBtn.on('click', evenHandlers.onSentenceBtnClick);
    };

    const evenHandlers = {
      
      onDocumentReady(e){
        helpers.hideSlides();
      },

      onTabClick(e) {
        e.preventDefault();
        const elem = e;
        const target = $(elem.target).data('target');
        const $target = $('#' + target);
        helpers.makeActive($(this), selectors.tabs);
        helpers.makeVisible($target, $('.js--tab__slide') );
      },

      onTabSentenceItem(e){
        e.preventDefault();
        helpers.displaOptionCtl('close');
        const elem = e;
        const labelText = $(elem.target).text();
        const target = $(elem.target).data('target');
        const $target = $('#' + target);
        helpers.makeVisible($target, $('.js--tab__slide') );
        helpers.activeFrameCtrl('undim');
        helpers.assignSentencLabel(labelText);
        helpers.sentenceBtnActiveCtrl('off');
      },

      onSentenceBtnClick(e){
        e.preventDefault();
        if(selectors.optionsList.is(':visible')){
          helpers.displaOptionCtl('close');
          helpers.activeFrameCtrl('undim');
          helpers.sentenceBtnActiveCtrl('off');
        }else{
          helpers.displaOptionCtl('open');
          helpers.activeFrameCtrl('dim');
          helpers.sentenceBtnActiveCtrl('on');
        }  
      }

    };

    const helpers = {
      // Makes an item active 
      makeActive($target, $siblings){
        if ($target.hasClass('active')) {
          $target.removeClass('active');
          return false;
        }
        const $inactivate = $siblings || $target.siblings(); 
        $inactivate.removeClass('active');
        $target.addClass('active');
        return $target; 
      }, 

      // shows a slide
      makeVisible($target, $siblings){
        if ($target.is(':visible')) {
          $target.slideUp();
          return false;
        }
        const $toBeHidden = $siblings || $target.siblings(); 
        $toBeHidden.hide();
        $target.show();
        return $target; 
      },

      // Hides all slides
      hideSlides(){
        $('.js--tab__slide').hide();
      },

      displaOptionCtl(state){
        const aniDuration = 100;
        if(state==='close'){
          selectors.optionsList.slideUp(aniDuration);  
        }else{
          selectors.optionsList.slideDown(aniDuration);
        }  
      },

      activeFrameCtrl(state){
        if($(window).width() > 780) return;
        if(state==='dim'){
          $('.tab-canvas__slide:visible').addClass('dimmed');
        }else{
          $('.tab-canvas__slide:visible').removeClass('dimmed');
        }  
        
      },
      assignSentencLabel(text){
        text = (text === 'collaborate') ? text + ' with' : text;
        selectors.sentenceBtn.find('a').text(text)
      },
      
      sentenceBtnActiveCtrl(state){
        if(state==='on'){
          selectors.sentenceBtn.find('a').addClass('active');
        }else{
          selectors.sentenceBtn.find('a').removeClass('active');
        }  
      }

    };
  }

  return this.init; 

}(jQuery);
